import React, { Component } from 'react';
import { navigate,graphql } from 'gatsby';
import { connect } from 'react-redux';
import styled from 'react-emotion';
import ReactPixel from 'react-facebook-pixel';
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';
import { toast } from 'react-toastify';
import moment from 'moment';
// import TagManager from 'react-gtm-module';
import Helmet from 'react-helmet';
import Modal from 'react-awesome-modal';
import Layout from '../../components/layout';
import Container from '../../components/container';
import DatePicker, { registerLocale } from 'react-datepicker';
import id from 'date-fns/locale/id';
import AsyncSelect from 'react-select/async';
import Select, { components, OptionProps } from 'react-select';
import GoogleMapReact from 'google-map-react';
import SearchBox from '../../components/SearchBox';
import './date.css'
import header from '../../components/header';
import qs from 'qs'

export const query = graphql`
  query {
    settings{
      data{
        idlefee,generalDiscount,insuranceCost,insuranceInfo,deposit
      }
    }
  }
`
const isSSR = typeof window === 'undefined';
if (!isSSR) {
  registerLocale('id', id);
}

const Expand = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" version="1.1" style={{width:'.71rem',height:'.71rem',transform:props.active?'rotate(180deg)':'rotate(0)',transition:'200ms ease-in-out'}}>
    <g id="surface1">
      <path style={{stroke:'none',fillRule:'nonzero',fill:'#5B5B5B',fillOpacity:1}} d="M 44.628906 12.867188 C 44.101563 12.882813 43.605469 13.097656 43.238281 13.476563 L 24.800781 31.917969 L 6.363281 13.476563 C 5.988281 13.09375 5.476563 12.878906 4.9375 12.878906 C 4.132813 12.878906 3.40625 13.371094 3.105469 14.117188 C 2.796875 14.867188 2.980469 15.722656 3.558594 16.285156 L 23.398438 36.125 C 24.171875 36.898438 25.429688 36.898438 26.203125 36.125 L 46.046875 16.285156 C 46.632813 15.71875 46.820313 14.847656 46.503906 14.089844 C 46.1875 13.335938 45.445313 12.851563 44.628906 12.867188 Z "/>
    </g>
  </svg>
)
const Spinner = () => (
  <svg className="lds-spinner" width="26px"  height="26px"  xmlns="http://www.w3.org/2000/svg" viewBox="20 20 60 60" preserveAspectRatio="xMidYMid" style={{background: 'none'}}><g transform="rotate(0 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#8520C5">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(30 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#8520C5">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(60 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#8520C5">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(90 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#8520C5">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(120 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#8520C5">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(150 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#8520C5">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(180 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#8520C5">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(210 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#8520C5">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(240 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#8520C5">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(270 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#8520C5">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(300 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#8520C5">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(330 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#8520C5">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
    </rect>
  </g></svg>
)
const Label = styled.label`
  ${tw`text-sm`};
`
const LabelForm = styled.label`
  ${tw`text-xs`};
  line-height:1;
`
const TextInput = styled.input`
  ${tw`w-full px-2`};
  &:focus{
    outline:none;
  }
`
const TextArea = styled.textarea`
  ${tw`w-full`}
  resize: none
`
const CheckoutButton = styled.button`
  ${tw`text-white rounded px-10 py-2 border-none cursor-pointer w-full`}
  background-color: #55BD22;
`
const FlexBoxs = styled.div`
  ${tw`flex flex-col md:flex-row pt-2`}
`
const FlexBoxs2 = styled.div`
  ${tw`flex flex-col md:flex-row md:justify-between pt-1`}
`
const Columns = styled.div`
  ${tw`w-full md:w-1/2 mb-8 px-2`}
`
const Columns2 = styled.div`
  ${tw`w-full md:w-1/2 text-left`}
`
const ColumnsLeft = styled.div`
  ${tw`w-full md:w-1/2 md:pr-2 text-left`}
`
const ColumnsRight = styled.div`
  ${tw`w-full md:w-1/2 md:pl-2 text-left`}
`
const Columns3 = styled.div`
  ${tw`w-full md:w-1/4 text-left`}
`
const Columns30 = styled.div`
  ${tw`w-full flex flex-col md:flex-row md:w-5/6 text-left`}
`
const Columns31 = styled.div`
  ${tw`w-full md:w-1/6 text-left`}
`
const ResponsiveCard = styled.div`
  ${tw`rounded p-4 shadow-md md:shadow-none`}
`
const Card = styled.div`
  ${tw`rounded p-4 shadow-md`}
`
const CheckoutButtonContainer = styled.div`
  ${tw`text-center`};
`
const SaveButtonContainer = styled.div`
  ${tw`text-right pt-4`};
`
const ProductTitle = styled.div`
  ${tw`mb-2`};
  line-height: 1;
  font-weight:600;
  font-size: .9rem;
`
const ProductPriceRow = styled.div`
  ${tw `flex justify-between`}
`
const ProductPriceContainer = styled.div`
  ${tw`text-sm flex leading-none`};
  color: grey;
  font-weight: 300;
  &.discounted{
    color:#5cb85c;
    margin-top:5px
  }
`
const Price = styled.div`
  ${tw`pr-1`};
`
const Multiplier = styled.div`
  ${tw`text-xs pr-2`};
`
const TotalPrice = styled.div`
  ${tw`font-bold`};
`
const SubTotal = styled.div`
  ${tw`text-2xl font-bold`}
  color: #E92028
`
const DivLeft = styled.div`
  ${tw`text-left pt-1`};
`
const Quantity = styled.div`
  ${tw`text-xs pr-2`};
`
const PromoButton = styled.span`
  ${tw`float-left bg-white cursor-pointer px-1`}
  border: solid 1px black;
  border-left-width:0;
  line-height:100%;
`
const PromoSubtitle = styled.div`
  ${tw`text-xs`}
`
const ModalBox = styled.div`
  ${tw`p-0`};
  border-radius: 5px 5px 0 0;
  overflow: hidden;
`
const ModalContent = styled.div`
  ${tw`px-4 pt-4 text-center`}
  max-height: calc(100vh - 100px);
  overflow-y: auto
`
const ButtonGroup = styled.div`
  ${tw`flex mt-4`}
`
const ButtonDirectPay = styled.button`
  ${tw`text-white text-sm rounded py-1 border-none cursor-pointer w-full`}
  background-color: #55bd22;
`
const ModalHeader = styled.h4`
    ${tw`font-semibold px-5 py-2 text-white flex justify-between mb-0`};
    font-family: Open Sans;
    background-color: #671aba;
`
const CloseModal = styled.span`
  ${tw`text-white`};
  cursor: pointer;
`
const ModalInsContent = styled.div`
  ${tw`text-left bg-white p-4`}
`
const FrameBoxs = styled.div`
  ${tw`mx-0 my-2 bg-white py-1 px-2 text-left text-sm rounded`}
`
const FrameRows = styled.div`
  ${tw`flex flex-row`}
`
const FrameCol = styled.div`
  ${tw`flex-grow font-semibold`};
  font-size: .75rem
`
const FrameColLeft = styled('FrameCol')`
  ${tw`flex-grow-0 mr-2`}
`
const AccordionContainer = styled.div`
  ${tw`my-3 mx-0 bg-white rounded overflow-hidden`}
`
const Accordion = styled.div`
  ${tw`mx-0 py-1 px-2 text-left text-xs font-semibold cursor-pointer`}
`
const AccordionHead = styled.div`
  ${tw`flex flex-row items-center`}
`
const AccordionBody = styled.div`
  padding: ${
      props => props.active ?
          "5px 0" :
          "0 0"
  };
  max-height: ${
    props => props.active ?
        "600px" :
        "0"
  };
  overflow: hidden;
  transition: 200ms ease-in-out;
`
const AccordionList=styled.ol`
  margin-left: .75rem;
  counter-reset: item;
  list-style-type: none;
  & li{
    display: block;
    margin-left: 1.5rem;
    margin-bottom: .2rem;
    position: relative;
    font-weight:normal;
    font-size: .65rem;
    &:before {
      content: counter(item) " ";
      counter-increment: item;
      position:absolute;
      top: .125rem;
      text-align: center;
      line-height: 180%;
      left: -1.5rem;
      width: 1.1rem;
      height: 1.1rem;
      background-color:#bbb;
      color: white;
      border-radius: 50%
    }
  }
`
const VANum = styled.span`
  color: rgb(233, 32, 40);
`
const BackButton = styled.button`
  ${tw`bg-white cursor-pointer`}
  color: #671aba;
  font-size: .75rem;
  font-weight: 700;
  border-radius: 4px;
  &:hover{
    color: white;
    background-color: #671aba;
  }
`
const DepDate = styled.span`
  border: solid 1px;
  display:block;
  padding: 2px 5px;
  cursor: pointer;
`
const InsCheckbox = styled.input`
  position:absolute;
  opacity:0;
  & + label{
    position:relative;
  }
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border:solid 1px #38980a;
  }
  &:checked + label:before {
    background: #55bd22;
  }
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 11px;
    background: white;
    width: 3px;
    height: 3px;
    box-shadow: 
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
`
const AddressButton = styled.button`
  ${tw`display-block w-full rounded bg-white`};
  display:block;
  font-size: .9rem;
  border-width: 1px;
  &:hover{
    font-weight: 600;
  }
`
const PointDiv = styled.div`
  ${tw`text-left p-1 flex flex-col md:flex-row md:justify-between`};
  background-color: #ededed;
  border : solid 1px #aaa;
  border-radius : 3px;
`
const PointText = styled.div`
  ${tw`text-left flex`};
  font-size: .8rem;
  align-items: center;
`
const PointLocation = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 265px;
  overflow: hidden;
`
const PointButton = styled.button`
  ${tw`bg-white cursor-pointer mb-0`}
  color: black;
  border-color: #aaa;
  font-size: .75rem;
  font-weight: 700;
  border-radius: 4px;
  &:hover{
    border-color: #999;
  }
`
const TextInputAdd = styled.input`
  ${tw`w-full px-2`};
  font-size: .85rem;
  border: solid 1px #ccc;
  border-radius: 4px;
  color: #333;
  &:focus{
    outline:none;
  }
`
const MapContainer = styled.div`
  height:450px;
  width: 100%;
  padding: .25rem;
  position: relative;
`
const MarkerSet = styled.div`
  ${tw`flex flex-col`}
  position: absolute;
  align-items: center;
  left: 50%;bottom:50%;
  transform: translateX(-50%);
`
const MapPoint = styled.button`
  color: white;
  border-color: #853BBB;
  background-color: #853BBB;
  font-size: .75rem;
  font-weight: 700;
  border-radius: 8px;
`
const ErrorText = styled.span`
  ${tw`text-red`}
  font-weight: 600;
  margin-left: 10px;
`
const AddressBox = styled.div`
  ${tw`flex flex-col w-full rounded bg-white p-4`};
  border:solid 1px #767676;
`
const AddressChange = styled.div`
  ${tw`flex flex-row justify-between items-center pb-3`};
  cursor: pointer;
  border-bottom: solid 1px #767676;
`
const AddressChangeText = styled.div`
  ${tw`flex-col flex`};
`
const AddressChangeHead = styled.h6`
  ${tw`mb-1`};
  font-size: .8rem;
`
const AddressChangeBody = styled.p`
  ${tw`m-0`};
  font-size: .7rem;
  line-height: 1.2;
`
const OngkirHead = styled.div`
  font-weight:600;
  font-size: .85rem;
`
const ExampleCustomInput = lesi => {
  return (
    <DepDate
      onClick={lesi.onClick}>
      {lesi.value?lesi.value:'pilih tanggal modem diterima'}
    </DepDate>
  )
}
const OptionShip = props => {
  const { innerProps, innerRef } = props;
  return (
    <div ref={innerRef} {...innerProps} className="custom-option" className="option-container">
      <div className="option-label1">{props.data.label}</div>
      <div className="option-label2">
        <CurrencyFormat
          value={props.data.min_price}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'Rp '}
        /> - 
        <CurrencyFormat
          value={props.data.max_price}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'Rp '}
        />
      </div>
    </div>
  );
};
const OptionCourier = props => {
  const { innerProps, innerRef } = props;
  return (
    <div ref={innerRef} {...innerProps} className="custom-option" className="option-container">
      <div className="option-label1">{props.data.label}</div>
      <div className="option-label2">
        <CurrencyFormat
          value={props.data.total_price}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'Rp '}
        />
      </div>
    </div>
  );
};
const FetchShipMsg = () => (
  <div>
    Gagal Mengambil Ongkir!<br/>
    <small>Mohon untuk merubah tanggal penerimaan modem.</small>
  </div>
)
class CheckoutPage extends Component {
  state = {
    name: '',
    address: '',
    postalCode: '',
    phoneNumber: '',
    email: '',
    orderNotes: '',
    promoCode: '',
    promoMessage: 'Pesan Promo',
    promoSuccess: false,
    promoShow: false,
    refMessage: 'Pesan Ref',
    refSuccess: false,
    refShow: false,
    promoCartList: [],
    promoType: 'Tipe Promo',
    promoMinSpend: 0,
    promoMaxValue: 0,
    promoAmount:0,
    deliveryMethod: null,
    departureDate: null,
    bankName: '',
    bankNumber: '',
    bankAccountOwner: '',
    showModal : false,
    paymentType: 'midtrans',
    idleDays:0,
    index:0,
    hasWifi:false,
    checkLoad:false,
    readTOC:false,
    vaLoaded:0,
    accordOpen:3,
    vaNumber: '',
    vaExpiry: '',
    fetchCode: null,
    useInsurance: false,
    showInsuranceInfo:false,
    disabledCity:true,
    disabledRegion:true,
    disabledDistrict:true,
    selectedProvince:null,
    selectedCity:null,
    selectedRegion:null,
    selectedDistrict:null,
    modalAddress: false,
    modalRefund: false,
    modalMap: false,
    refund: null,
    pinpoint:null,
    lat:-6.1753924,
    lng:106.8271528,
    map:null,
    maps:null,
    mapReady:false,
    addressError:null,
    loadingShipInfo: false,
    shippingInfo: null,
    shippingType: null,
    shippingOptions: null,
    selectedShipping: null,
    selectedCourier: null,
    destination: null,
  }
  constructor(props) {
    super(props);
    this.changeDepartureDateHandler = this.changeDepartureDateHandler.bind(this);
    this.changeNameHandler = this.changeNameHandler.bind(this);
    this.changeAddressHandler = this.changeAddressHandler.bind(this);
    this.changePostalCodeHandler = this.changePostalCodeHandler.bind(this);
    this.changePhoneNumberHandler = this.changePhoneNumberHandler.bind(this);
    this.changeEmailHandler = this.changeEmailHandler.bind(this);
    this.changeOrderNotesHandler = this.changeOrderNotesHandler.bind(this);
    this.changeDeliveryMethodHandler = this.changeDeliveryMethodHandler.bind(this);
    this.changePromoHandler = this.changePromoHandler.bind(this);
    this.changeReferralHandler = this.changeReferralHandler.bind(this);
    this.checkoutHandler = this.checkoutHandler.bind(this);
    this.changeBankNumberHandler = this.changeBankNumberHandler.bind(this);
    this.changeBankNameHandler = this.changeBankNameHandler.bind(this);
    this.changeBankAccountOwnerHandler = this.changeBankAccountOwnerHandler.bind(this);
    this.changePaymentHandler = this.changePaymentHandler.bind(this);

    // this.departureRef = React.createRef();
    // this.shippingRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.shoppingCart.length === 0 || this.getTotalDuration() < 3) {
      navigate('/');
    }
    this.setState({
      name: this.props.userInfo.name,
      address: this.props.userInfo.address,
      postalCode: this.props.userInfo.postalCode,
      phoneNumber: this.props.userInfo.phoneNumber,
      email: this.props.userInfo.email,
      refund: this.props.userInfo.refund,
      bankName: this.props.userInfo.refund?this.props.userInfo.refund.bankName:'',
      bankNumber: this.props.userInfo.refund?this.props.userInfo.refund.bankAccount:'',
      bankAccountOwner: this.props.userInfo.refund?this.props.userInfo.refund.bankAccountOwner:'',
      pinpoint:this.props.userInfo.pinpoint,
      selectedProvince: this.props.userInfo.selectedProvince,
      selectedCity: this.props.userInfo.selectedCity,
      selectedRegion: this.props.userInfo.selectedRegion,
      selectedDistrict: this.props.userInfo.selectedDistrict,
      disabledCity : this.props.userInfo.selectedCity == null,
      disabledRegion: this.props.userInfo.selectedRegion == null,
      disabledDistrict: this.props.userInfo.selectedDistrict == null,
      hasWifi: (this.props.shoppingCart.filter((p) => p.type === 'wifi').length > 0 ),
      useInsurance: false,//(this.props.shoppingCart.filter((p) => p.type === 'wifi').length > 0 ),
      departureDate: new Date(this.getEarliestStartDate()),
    },()=>{
      if (this.state.selectedProvince && this.state.selectedCity && this.state.selectedRegion && this.state.selectedDistrict && this.state.hasWifi){
        this.fetchShipping();
      }
    });

    // const tagManagerArgs = {
    //   gtmId: 'GTM-PSHLP3G',
    //   dataLayerName: 'paymentSuccess'
    // }
    // TagManager.initialize(tagManagerArgs);
    this.applyPromoHandler('referral');
    this.timer = null;
  }
  componentWillUnmount() {
    this.props.changePromo('');
  }
  changeBankNumberHandler() {
    this.setState({ bankNumber: event.target.value.replace(/\D/,'') });
  }
  changeBankNameHandler() {
    this.setState({ bankName: event.target.value });
  }
  changeBankAccountOwnerHandler() {
    this.setState({ bankAccountOwner: event.target.value });
  }
  changeDepartureDateHandler(event) {
    if (new Date(moment().format('YYYY-MM-DD')) <= event){
      let earliest = moment(this.getEarliestStartDate()).subtract(2,'days');
      let depDate = moment(event);
      // console.log(new Date(this.getEarliestStartDate()) < event)
      this.setState({ 
        deliveryMethod: null,
        departureDate: new Date(depDate.format('YYYY-MM-DD')),
        idleDays: (depDate.isBefore(moment(earliest))) ? (earliest.diff(depDate, 'days') + (this.state.departureDate==null?0:1)) : 0
      });
    }
  }
  changeNameHandler(event) {
    this.setState({ name: event.target.value.replace(/\d/,'') });
  }
  changeAddressHandler(event) {
    if (event.target.value.length <= 90){
      this.setState({ address: event.target.value });
    }
  }
  changePostalCodeHandler(event) {
    this.setState({ postalCode: event.target.value.replace(/\D/,'') });
  }
  changePhoneNumberHandler(event) {
    this.setState({ phoneNumber: event.target.value.replace(/\D/,'') });
  }
  changeEmailHandler(event) {
    this.setState({ email: event.target.value });
  }
  changeOrderNotesHandler(event) {
    this.setState({ orderNotes: event.target.value });
  }
  changeDeliveryMethodHandler(event) {
    this.setState({ deliveryMethod: event.target.value },()=>{
      // console.log(this.state.deliveryOpts[this.state.deliveryMethod]);
    });
  }
  changePromoHandler(value) {
    clearTimeout(this.timer);
    this.setState({ promoCode: value },()=>{
      if (value.length>4)
        this.timer = setTimeout(()=>this.applyPromoHandler('discount'), 1000);
    });    
  }
  changePaymentHandler(value){
    this.setState({ paymentType: value });
  }
  changeReferralHandler(event) {
    clearTimeout(this.timer);
    this.props.changeReferral(event.target.value);
    if (event.target.value.length>4)
      this.timer = setTimeout(()=>this.applyPromoHandler('referral'), 1000);
    
  }
  handleChangeIndex = index => {
    this.setState({index,})
  }
  getTotalDuration() {
    let totalDuration = 0;
    if (this.props.shoppingCart.filter((p) => p.type === 'wifi').length > 0 ) {
      this.props.shoppingCart.forEach((p) => {
        if (p.type === 'wifi') {
          totalDuration += p.duration * p.quantity
        }
      })
    } else {
      totalDuration = 3;
    }

    return totalDuration;
  }
  getEarliestStartDate() {
    let earliestStartDate;
    
    if (this.props.shoppingCart.filter((p) =>( p.type === 'wifi' || p.type==='simcard')).length > 0 ) {
      earliestStartDate = this.props.shoppingCart.find((p) => (p.type === 'wifi' || p.type==='simcard')).startDate;
      this.props.shoppingCart.forEach((p) => {
        if (moment(p.startDate).isBefore(earliestStartDate)) {
          earliestStartDate = p.startDate;
        }
      })
    } else {
      earliestStartDate = moment().add(4, 'days').format('YYYY-MM-DD');
    }

    return earliestStartDate;
  }
  handleEnterKey = (type,e) => {
    if (e.keyCode==13){
      e.stopPropagation();
      e.preventDefault();
      clearTimeout(this.timer);
      this.applyPromoHandler(type);
    }
  }
  applyPromoHandler = (type) => {
    if (this.state.fetchCode==null){
      const products = this.props.shoppingCart.map((product) => {
        return {
          product_id: product.id,
          qty: product.quantity,
          start_date: product.startDate,
          end_date: product.lastDate,
        }
      });

      this.setState({fetchCode:type});
      
      axios.post(process.env.GATSBY_API_URL + 'api/v091/applypromo', {
        ref_code: this.props.referralCode,
        promo_code: this.state.promoCode,
        products
      }).then((res) => {
        this.setState({ 
          promoShow: this.state.promoCode?true:false,
          refShow: this.props.referralCode?true:false
        });
        if (res.data.status === 'success') {
          this.setState({
            refSuccess: (res.data.ref_status)?true:false,
            refMessage: res.data.ref_status?res.data.ref_status:"Referral Code tidak ditemukan",
            promoSuccess: (res.data.disc_status)?true:false,
            promoMessage: (res.data.disc_status)?res.data.disc_status + (res.data.disc_usage?'':' (silahkan pelajari S&K untuk memakai kode ini'):'Promo Code tidak ditemukan',
            promoType : res.data.discount_type,
            promoMinSpend : res.data.discount_min_spend,
            promoMaxValue : res.data.discount_max_value,
            promoAmount: res.data.discount_amount,
            promoCartList: res.data.data
          },()=>{
            this.props.changePromo(this.state.promoCode);
          })
        } else {
          this.setState({
            refSuccess: (res.data.ref_status)?false:true,
            refMessage: (res.data.ref_status)?res.data.ref_status:'',
            promoSuccess: (res.data.disc_status)?false:true,
            promoMessage: (res.data.disc_status)?res.data.disc_status:'',
            promoType : '',
            promoMinSpend : 0,
            promoMaxValue : 0,
            promoCartList: []
          })
          this.props.changePromo('');
        }
      }).catch((err) => {
        console.log(err)
        toast.error(err.message, {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true
        });
      }).finally(()=>{
        this.setState({fetchCode:null})
      })
    }
  }
  checkoutHandler(event) {
    event.preventDefault()
    if (!(this.state.destination&&this.state.selectedShipping) && this.state.hasWifi){
      toast.error('Silahkan masukkan alamat dan pilih pengiriman', {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
      return 
    }
    if (!(this.state.phoneNumber&&this.state.name&&this.state.email) && !this.state.hasWifi){
      toast.error('Silahkan lengkapi detail Pesanan', {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
      return 
    }
    if (!this.state.refund && this.state.hasWifi){
      toast.error('Silahkan masukkan rekening refund', {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
      return 
    }
    if (!this.state.readTOC){
      toast.error('Anda belum menyetujui syarat dan ketentuan dari travelwifi', {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
      return 
    }
    console.log('Checkout');
    this.processCheckout();
    // if (this.state.paymentType=='midtrans'){
    //   this.processCheckout();
    // } else {
    //   console.log('pake modal');
    //   this.setState({showModal:true});
    // }
    
  }
  processCheckout(){
    this.setState({checkLoad:true})
    const {selectedCourier} = this.state;
    const products = this.props.shoppingCart.map((product) => {
      return {
        product_id: product.id,
        qty: product.quantity,
        start_date: product.startDate,
        end_date: product.lastDate,
      }
    });
    const userInfo = {
      name: this.state.name,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      address: this.state.address,
      postalCode: this.state.postalCode,
      selectedProvince:this.state.selectedProvince,
      selectedCity:this.state.selectedCity,
      selectedRegion:this.state.selectedRegion,
      selectedDistrict:this.state.selectedDistrict,
      refund: this.state.refund,
      pinpoint:this.state.pinpoint,
      lat:this.state.lat,
      lng:this.state.lng,
    }
    this.props.updateUserInfo(userInfo);
    console.log({selectedCourier});
    var payload = {
      products,
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phoneNumber,
      order_notes: this.state.orderNotes,
      referral_code: this.props.referralCode,
      promo_code: this.props.promoCode,
      device_num: this.props.numberOfDevices,
      departure_date: this.state.departureDate,
      bank_number: this.state.refund.bankAccount,
      bank_name: this.state.refund.bankName,
      bank_account_owner: this.state.refund.bankAccountOwner,
      payment: this.state.paymentType,
      use_insurance: this.state.useInsurance,
      idle_day: this.state.idleDays,
    };
    if (this.state.hasWifi){
      payload.address = (`${this.state.address}, ${this.state.selectedDistrict?this.state.selectedDistrict.label:''}, ${this.state.selectedRegion?this.state.selectedRegion.label:''}, ${this.state.selectedCity?this.state.selectedCity.label:''}, ${this.state.selectedProvince?this.state.selectedProvince.label:''}`).substring(0,254);
      payload.postal_code= this.state.postalCode;
      payload.delivery_method= this.state.deliveryMethod;
      payload.destination = this.state.destination;
      payload.rate = {
          id: selectedCourier.rate_id,
          name: selectedCourier.rate_name,
          type: selectedCourier.rate_type,
          total_price: selectedCourier.total_price,
        };
      payload.logistic = {
        name: selectedCourier.logistic_name,
        code: selectedCourier.logistic_code,
        logo_img: selectedCourier.logistic_logo_img
      }
    }
    console.log({payload});
    if (this.state.paymentType=='bcava'){
      this.setState({showModal:true,vaLoaded:0});
    }
    var value = this.getTotal() - (this.props.numberOfDevices*this.props.data.settings.data.deposit);
              
    ReactPixel.track('Purchase', {
      value: value,
      currency: 'IDR'
    });
    
    // this.gtag_report_conversion(value);
    
    axios.post(process.env.GATSBY_API_URL + 'api/v091/getSnapToken', payload)
      .then((res) => {
        if (this.state.paymentType=='midtrans'){
          snap.pay(res.data.token, {
            onSuccess: (result) => {
              console.log('success', result);
              // this.props.changePromo('');
              // this.props.changeReferral('');
              this.props.clearCart();
              navigate('/checkout/finish');
            },
            onPending: (result) => {
              console.log('pending');
              // this.props.changePromo('');
              // this.props.changeReferral('');
              this.props.clearCart();
              navigate('/checkout/pending');
            },
            onError: (result) => {
              console.log('error');
              console.log(result);
              axios.post(process.env.GATSBY_API_URL + 'api/v091/cancelorder', {
                order_id: res.data.order_id
              });
            },
            onClose: () => {
              console.log('customer closed the popup without finishing the payment');
              axios.post(process.env.GATSBY_API_URL + 'api/v091/cancelorder', {
                order_id: res.data.order_id
              });
            }
          })
        } else if (this.state.paymentType=='bcava'){
          var datas = res.data.token;
          console.log(datas)
          if (datas.status===200 && datas.data.Status>0){
            this.setState({
              vaLoaded:1,
              vaNumber:datas.data.VirtualAccountAssigned,
              vaExpiry:moment(datas.data.TransactionExpiryDate,'DD-MM-YYYY HH:mm').format("dddd, DD MMMM YYYY, HH:mm")
            });
          } else {
            this.setState({vaLoaded:-1})
          }
        }
      }).catch((err) => {
        toast.error(err.response.data.message, {
          position: "top-center",
          onClose: props=>{
            if (err.response.status==404){
              this.props.clearCart();
              navigate('/cart')
            };
          },
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true
        });
        
      }).finally(()=>{
        this.setState({checkLoad:false})
      })
  }
  closeModal(){
    this.setState({showModal:false});
  }
  directProcess(){
    this.setState({showModal:false});
    navigate('/checkout/pending');
    // this.processCheckout();
  }
  // gtag_report_conversion(value, url) {
  //   var callback = function () {
  //     if (typeof(url) != 'undefined') {
  //       window.location = url;
  //     }
  //   };
  //   gtag('event', 'conversion', {
  //       'send_to': 'AW-813419406/xnrcCPSV84oBEI6X74MD',
  //       'value': value,
  //       'currency': 'IDR',
  //       'transaction_id': '',
  //       'event_callback': callback
  //   });
  //   ga('send', 'event', 'checkout','proses order', this.state.email, value)
    
  //   return false;
  // }
  getTotal() {
    let total = 0 ;
    for (let i = 0; i < this.props.shoppingCart.length; i++) {
      total += (this.props.data.settings.data.generalDiscount?this.props.shoppingCart[i].price:this.props.shoppingCart[i].discountPrice) * this.props.shoppingCart[i].quantity * (this.props.shoppingCart[i].type === 'wifi' ? this.props.numberOfDevices : 1);
    }
    return total + (this.state.useInsurance?this.props.data.settings.data.insuranceCost*this.props.numberOfDevices:0) +(this.props.data.settings.data.deposit * this.props.numberOfDevices)+(this.state.selectedCourier?this.state.selectedCourier.total_price:0);
  }
  getTotalAfterPromo() {
    let total = 0 + (this.state.idleDays*this.props.data.settings.data.idlefee);
    let before = 0;
    let dayspent = 0;
    let price_cut = 0;
    
    let totalbefore = 0 ;
    for (let i = 0; i < this.props.shoppingCart.length; i++) {
      totalbefore += 
      (this.props.data.settings.data.generalDiscount?this.props.shoppingCart[i].price:this.props.shoppingCart[i].discountPrice)
       * this.props.shoppingCart[i].quantity * (this.props.shoppingCart[i].type === 'wifi' ? this.props.numberOfDevices : 1);
    }
    totalbefore += (this.props.data.settings.data.deposit * this.props.numberOfDevices);
    totalbefore += (this.state.useInsurance?this.props.data.settings.data.insuranceCost*this.props.numberOfDevices:0);
    return totalbefore - (this.state.promoAmount*(this.props.numberOfDevices?this.props.numberOfDevices:1)) + (this.state.idleDays*this.props.data.settings.data.idlefee*this.props.numberOfDevices)+(this.state.selectedCourier?this.state.selectedCourier.total_price:0)
  }
  getPromoTotal = () =>{
    // if (this.props.data.settings.data.generalDiscount){
    //   let totalPromo = 0 ;
    //   var price_cut = 0;
    //   for (let i = 0; i < this.props.shoppingCart.length; i++) {
    //     if (this.state.promoCartList.length){
    //       price_cut = Math.max(this.state.promoCartList.find(p => (p.id == this.props.shoppingCart[i].id)).real_cut,this.props.shoppingCart[i].price-this.props.shoppingCart[i].discountPrice);
    //     } else {
    //       price_cut = this.props.shoppingCart[i].price-this.props.shoppingCart[i].discountPrice;
    //     }
    //     totalPromo += price_cut * this.props.shoppingCart[i].quantity * (this.props.shoppingCart[i].type === 'wifi' ? this.props.numberOfDevices : 1);
    //   }
    //   return totalPromo;
    // }else{
      if (this.state.promoCartList.length){
        return this.state.promoAmount*(this.props.numberOfDevices?this.props.numberOfDevices:1);
      } else{
        return 0;
      }
    // }
  }
  updateTotal() {
    let total = 0;
    let addDeposit = false;
    if (this.state.promoCartList.length === 0) {
      for (let i = 0; i < this.props.shoppingCart.length; i++) {
        if (!addDeposit) {
          addDeposit = this.props.shoppingCart[i].type === 'wifi';
        }
        total += this.props.shoppingCart[i].totalCost;
      }
    } else {
      for (let i = 0; i < this.props.shoppingCart.length; i++) {
        if (!addDeposit) {
          addDeposit = this.props.shoppingCart[i].type === 'wifi';
        }
        total += this.props.shoppingCart[i].quantity * this.state.promoCartList.find(p => (p.id == this.props.shoppingCart[i].id)).discount_price;
      }
    }
    return (total + (addDeposit ? this.props.data.settings.data.deposit * this.props.numberOfDevices : 0));
  }
  getIdleDates = () => {
    var $scope=[];
    $scope.dateArr = [];
    $scope.prevDate = moment().add(2, 'days');
    $scope.nextDate = moment(this.getEarliestStartDate()).subtract(3, 'days');
    $scope.prevDate = moment($scope.prevDate._d).format('MM-DD-YYYY');
    $scope.nextDate = moment($scope.nextDate._d).format('MM-DD-YYYY');

    var start = new Date($scope.prevDate);
    var end = new Date($scope.nextDate);

    
    while(start < end){
      $scope.dateArr.push(start);
      var newDate = start.setDate(start.getDate() + 1);
      start = new Date(newDate);  
    }
    return [
      { "react-datepicker__day--highlighted-custom-1": $scope.dateArr }
    ]
  }
  MarkersOff = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" style={{marginRight:'10px'}}>
      <path d="M11 -0.7H13V24.8H11z" transform="rotate(-45.001 12 12)" fill="#ABB99D" />
      <path d="M16.9 12.7C17.6 11.4 18 10.1 18 9c0-3.3-2.7-6-6-6-1.3 0-2.6.4-3.6 1.2L16.9 12.7zM7.1 5.6C6.4 6.6 6 7.7 6 9c0 4 6 11 6 11s2.2-2.6 3.9-5.5L7.1 5.6z" opacity=".3" fill="#ABB99D" />
      <path d="M9.2 4.9C10 4.3 10.9 4 12 4c2.8 0 5 2.2 5 5 0 .8-.3 1.9-.8 3l1.5 1.5C18.5 11.9 19 10.3 19 9c0-3.9-3.1-7-7-7-1.6 0-3.1.5-4.3 1.5L9.2 4.9zM15.3 13.8c-1 1.9-2.3 3.7-3.3 5-1.9-2.5-5-7.1-5-9.8 0-1 .3-1.9.8-2.7L6.3 4.9C5.5 6.1 5 7.5 5 9c0 4.5 6 11.8 6.2 12.1L12 22l.8-.9c.1-.2 2.3-2.8 4-5.8L15.3 13.8z" fill="#ABB99D" />
      <g>
        <path d="M14.3,10c0.1-0.3,0.2-0.7,0.2-1c0-1.4-1.1-2.5-2.5-2.5c-0.4,0-0.7,0.1-1,0.2L14.3,10z" fill="#ABB99D" />
      </g>
    </svg>
  )
  Markers = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" style={{marginRight:'10px'}}>
      <path d="M18,9c0,3.314-6,11-6,11S6,12.314,6,9s2.686-6,6-6S18,5.686,18,9z" opacity=".3" fill="#853BBB" />
      <path d="M12,11.5c-1.381,0-2.5-1.119-2.5-2.5c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5C14.5,10.381,13.381,11.5,12,11.5z" fill="#853BBB" />
      <path d="M12,2.01c-3.856,0-6.993,3.137-6.993,6.993c0,4.483,5.968,11.767,6.222,12.074L12,22.012l0.771-0.935c0.254-0.308,6.222-7.591,6.222-12.074C18.993,5.146,15.856,2.01,12,2.01z M12,18.827c-1.878-2.463-4.993-7.115-4.993-9.824C7.007,6.25,9.247,4.01,12,4.01s4.993,2.24,4.993,4.993C16.993,11.708,13.878,16.362,12,18.827z" fill="#853BBB" />
    </svg>
  )
  Forward = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" height="23" width="23">
      <path d="M12.96875 4.28125L11.53125 5.71875L21.8125 16L11.53125 26.28125L12.96875 27.71875L23.96875 16.71875L24.65625 16L23.96875 15.28125Z" fill="#767676" />
    </svg>
  )
  openAddressModal = () => {
    this.setState({modalAddress:true}); 
  }
  selectProv = (selectedProvince) => {
    this.setState({ selectedProvince,
      disabledCity:false,
      selectedCity:null,
      selectedRegion:null,
      disabledRegion:true,
      selectedDistrict:null,
      disabledDistrict:true,
      postalCode:'',lat:null,lng:null,
    });
  };
  selectCity = (selectedCity) => {
    this.setState({ selectedCity, 
      disabledRegion:false,
      selectedDistrict:null,
      selectedRegion:null,
      disabledDistrict:true,
      postalCode:'',lat:null,lng:null,
    });
  };
  selectRegion = (selectedRegion) => {
    this.setState({ 
      selectedRegion, 
      disabledDistrict:false,
      selectedDistrict:null,
      postalCode:'',lat:null,lng:null,
     });
  };
  selectDistrict = (selectedDistrict) => {
    this.setState({ selectedDistrict,
      postalCode:selectedDistrict.postalcode,
      lat:selectedDistrict.lat,
      lng:selectedDistrict.lng, pinpoint: null});
  };
  loadProvinces = (inputValue)=>
    new Promise ((resolve)=>{
        axios.get(`https://api.travelwifi.id/api/location/provinces/228`)
        .then(({data}) => {
          var opts = data.data.map(el => ({value:el.id,label:el.name}))
          resolve(opts.filter(i=> i.label.toLowerCase().includes(inputValue.toLowerCase())));
        })
        .catch((error) => {
          console.log({error});
          toast.error('Gagal memuat provinsi', {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true
          });
        })
  })
  loadCities = (inputValue)=>{
    const {selectedProvince} = this.state;
    return selectedProvince ? new Promise ((resolve)=>{
        axios.get(`https://api.travelwifi.id/api/location/cities/${selectedProvince?selectedProvince.value:''}`)
        .then(({data}) => {
          var opts = data.data.map(el => ({value:el.id,label:el.name}))
          resolve(opts.filter(i=> i.label.toLowerCase().includes(inputValue.toLowerCase())));
        })
        .catch((error) => {
          console.log({error});
          toast.error('Gagal memuat kota/kabupaten', {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true
          });
        })
    }) : [];
  }
  loadRegion = (inputValue)=>{
    const {selectedCity} = this.state;
    return selectedCity ? new Promise ((resolve)=>{
        axios.get(`https://api.travelwifi.id/api/location/suburbs/${selectedCity?selectedCity.value:''}`)
        .then(({data}) => {
          var opts = data.data.map(el => ({value:el.id,label:el.name}))
          resolve(opts.filter(i=> i.label.toLowerCase().includes(inputValue.toLowerCase())));
        })
        .catch((error) => {
          console.log({error});
          toast.error('Gagal memuat kecamatan', {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true
          });
        })
    }) : [];
  }
  loadDistrict = (inputValue)=>{
    const {selectedRegion} = this.state;
    return selectedRegion ? new Promise ((resolve)=>{
        axios.get(`https://api.travelwifi.id/api/location/areas/${selectedRegion?selectedRegion.value:''}`)
        .then(({data}) => {
          var opts = data.data.map(el => ({value:el.id,label:el.name,postalcode:el.postcode,lat:el.lat,lng:el.lng}))
          resolve(opts.filter(i=> i.label.toLowerCase().includes(inputValue.toLowerCase())));
        })
        .catch((error) => {
          console.log({error});
          toast.error('Gagal memuat kelurahan', {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true
          });
        })
    }) : []
  }
  handleChangeShipping = selectedShipping => {
    this.setState({
      selectedShipping,
      selectedCourier:this.state.shippingInfo[selectedShipping.value].data[0],
    })
  }
  handleChangeCourier = selectedCourier => {
    this.setState({selectedCourier})
  }
  FirstFormPanel = () =>{ 
    const {addressError} = this.state;
    let cart = this.props.shoppingCart.map((product, id) => {
    let discountedProduct = this.state.promoCartList.find(p => p.id == product.id);
      return {
        key: product.key,
        title: product.title,
        startDate: product.startDate,
        lastDate: product.lastDate,
        price: product.price,
        quantity: product.quantity,
        totalCost: product.quantity * ((discountedProduct) ? discountedProduct.discount_price : product.discountPrice),
        discountPrice: product.discountPrice,
        promoPrice: (discountedProduct) ? discountedProduct.discount_price : product.discountPrice,
        type: product.type,
        promoName: (discountedProduct)?discountedProduct.disc_type:'',
        promoAmount: (discountedProduct)?discountedProduct.real_cut*(product.type=='wifi'?this.props.numberOfDevices:1):0
      };
    });
    
    return (
      <FlexBoxs key="firstPane">
      <Columns>
        <Card>
          {this.state.hasWifi?
          <div className="item-group">
            <Label><strong>Pengiriman</strong></Label>
            {this.state.loadingShipInfo?
            <div><Spinner /></div>:
            this.state.shippingInfo?
            <AddressBox>
              <AddressChange onClick={()=>this.setState({modalAddress:true})}>
                <AddressChangeText>
                  <AddressChangeHead>{this.state.name} ({this.state.phoneNumber})</AddressChangeHead>
                  <AddressChangeBody> {this.state.address}, {this.state.selectedDistrict?this.state.selectedDistrict.label:''}, {this.state.selectedRegion?this.state.selectedRegion.label:''}, {this.state.selectedCity?this.state.selectedCity.label:''}, {this.state.selectedProvince?this.state.selectedProvince.label:''}</AddressChangeBody>
                </AddressChangeText>
                <this.Forward />
              </AddressChange>
              <DivLeft>
                <LabelForm>
                  Estimasi Modem Diterima: <strong>{moment(this.state.departureDate).format('DD MMMM YYYY')}</strong> 
                </LabelForm>
              </DivLeft>
              {this.state.shippingType.length?
              <FlexBoxs>
                <Columns2 style={{paddingRight:'15px'}}>
                  <AddressChangeBody>Pilih Pengiriman</AddressChangeBody>
                  <Select
                    value={this.state.selectedShipping}
                    onChange={this.handleChangeShipping}
                    options={this.state.shippingType}
                    components={{Option: OptionShip}}
                    className="selectar-container"
                    classNamePrefix="selectar"
                  />
                </Columns2>
                <Columns2 style={{paddingLeft:'15px'}}>
                  <AddressChangeBody>Pilih Kurir</AddressChangeBody>
                  <Select
                    key={JSON.toString(this.state.selectedShipping)}
                    value={this.state.selectedCourier}
                    onChange={this.handleChangeCourier}
                    components={{Option: OptionCourier}}
                    options={this.state.selectedShipping?this.state.shippingInfo[this.state.selectedShipping.value].data:[]}
                    className="selectar-container"
                    classNamePrefix="selectar"
                  />
                </Columns2>
              </FlexBoxs>:<ErrorText style={{lineHeight:1,fontSize:'.8rem'}}>Tidak ada pilihan pengiriman pada tanggal tersebut, silahkan pilih tanggal lain</ErrorText>}
            </AddressBox>:
            <AddressButton type="button" onClick={this.openAddressModal}>Masukkan alamat pengiriman</AddressButton>
            }
          </div>:
          <div className="item-group">
            <Label><strong>Detail Pesanan</strong></Label>
            <FlexBoxs2>
              <Columns2 style={{paddingRight:'15px'}}>
                <LabelForm>
                  Nama Lengkap*
                  {addressError&&addressError.name?<ErrorText>{addressError.name}</ErrorText>:null}
                </LabelForm>
                <TextInputAdd type="text" value={this.state.name || ''} onChange={this.changeNameHandler} />
              </Columns2>
              <Columns2 style={{paddingLeft:'15px'}}>
                <LabelForm>
                  Nomor Telepon*
                  {addressError&&addressError.phone?<ErrorText>{addressError.phone}</ErrorText>:null}
                </LabelForm>
                <TextInputAdd type="text" value={this.state.phoneNumber || ''} onChange={this.changePhoneNumberHandler}></TextInputAdd>
              </Columns2>
            </FlexBoxs2>
            <DivLeft>
              <LabelForm>
                Email aktif (untuk pengiriman invoice)*
                {addressError&&addressError.email?<ErrorText>{addressError.email}</ErrorText>:null}
                </LabelForm>
              <TextInputAdd type="text" value={this.state.email || ''} onChange={this.changeEmailHandler}></TextInputAdd>
            </DivLeft>
          </div>
          }
          {this.state.hasWifi?
            <div className="item-group">
              <Label><strong>Rekening Pengembalian Deposit</strong></Label>
              <TextInput readOnly type="text" onClick={()=>this.setState({modalRefund: true})} value={this.state.refund?`${this.state.refund.bankAccountOwner}/${this.state.refund.bankName}/${this.state.refund.bankAccount}`:''} />
            </div>
            :null
          }
          <div className="item-group">
            <Label htmlFor="order-notes"><strong>Order Notes (optional)</strong></Label>
            <TextArea id="order-notes" type="text" value={this.state.orderNotes} disabled={this.state.checkLoad} onChange={this.changeOrderNotesHandler} rows="3" maxLength="255" />
            <div style={{display:'flex',alignItems:'center',marginTop:'.5rem'}}>
              <InsCheckbox type="checkbox" id="tosread" style={{marginRight:'5px'}}
                  defaultChecked={this.state.readTOC}
                  onChange={()=>this.setState({readTOC: !this.state.readTOC})}
              />
              <label htmlFor="tosread" style={{fontSize:'.8rem',fontWeight:600}}>
                Saya menyetujui <a href="https://travelwifi.id/syarat-dan-ketentuan-travelwifi/" target="_blank" style={{fontStyle:'italic'}} >syarat dan ketentuan</a> yang berlaku
              </label>
            </div>
          </div>
          
        </Card>
      </Columns>
      <Columns>
        <ResponsiveCard>  
          <div className="item-group">
            <Label htmlFor="promoCode">Promo Code</Label>
            <div
              style={{
                display: 'flex'
              }}
            >
              <TextInput id="promoCode" onKeyDown={(e)=>this.handleEnterKey('discount',e)} onChange={e=>this.changePromoHandler(e.target.value) } value={ this.state.promoCode } style={{border:"solid 1px",borderRightWidth:'0'}}/>
              <PromoButton className={this.state.fetchCode?"disabled":""}>{this.state.fetchCode=='discount'?<Spinner />:''}</PromoButton>
            </div>
            {
              (this.state.promoShow)
                ? <PromoSubtitle
                    style={{ 
                      color: (this.state.promoSuccess) ? 'green' : 'red'
                    }}
                  >
                    {this.state.promoMessage}
                  </PromoSubtitle>
                : null
            }
            </div>
          
          <div className="item-group">
            <Label htmlFor="referralCode">Referral Code</Label>
            <div style={{display:'flex'}}>
              <TextInput id="referralCode" onKeyDown={(e)=>this.handleEnterKey('referral',e)}  onChange={ this.changeReferralHandler } value={ this.props.referralCode } style={{border:"solid 1px",borderRightWidth:'0'}}/>
              <PromoButton className={this.state.fetchCode?"disabled":""}>{this.state.fetchCode=='referral'?<Spinner />:''}</PromoButton>
            </div>
            {
              (this.state.refShow)
                ? <PromoSubtitle
                    style={{ 
                      color: (this.state.refSuccess) ? 'green' : 'red'
                    }}
                  >
                    {this.state.refMessage}
                  </PromoSubtitle>
                : null
            }
          </div>
            {
              cart.map((product, i) => (
                <div className="item-group" key={product.key}>
                  <ProductTitle>
                    {product.title}
                    {
                      (product.type === 'wifi') ? (
                        <div
                          style={{ 
                            fontSize: '0.75rem',
                            opacity: 0.6
                          }}
                        >
                          {` (${moment(product.startDate).format('DD/MM/YYYY')} - ${moment(product.lastDate).format('DD/MM/YYYY')})`}
                        </div>
                      ) : ( null )
                    }
                  </ProductTitle>
                  <ProductPriceRow>
                    <ProductPriceContainer
                      style={{
                        opacity: (product.type === 'wifi') ? 0.5 : 1
                      }}
                    >
                      <Price>
                        <CurrencyFormat
                          value={this.props.data.settings.data.generalDiscount?product.price:product.discountPrice}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Rp '}
                        />
                      </Price>
                      <Multiplier>
                        {`x${product.quantity}`}
                      </Multiplier>
                      <TotalPrice>
                        <CurrencyFormat
                          value={(this.props.data.settings.data.generalDiscount?product.price:product.discountPrice) * product.quantity}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Rp '}
                        />
                      </TotalPrice>
                    </ProductPriceContainer>
                    {
                      product.type === 'wifi' ? (
                        <ProductPriceContainer>
                          <Quantity>
                            {`(${this.props.numberOfDevices} device)`}
                          </Quantity>
                          <TotalPrice
                            style={{ marginRight: '5px' }}
                          >
                            <CurrencyFormat
                              value={(this.props.data.settings.data.generalDiscount?product.price:product.discountPrice) * product.quantity * this.props.numberOfDevices}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'Rp '}
                            />
                          </TotalPrice>
                        </ProductPriceContainer>
                      ) : (
                        null
                      )
                    }
                  </ProductPriceRow>
                {product.promoAmount ?
                  <ProductPriceRow>
                    <ProductPriceContainer className="discounted">
                      ({product.promoName})
                    </ProductPriceContainer>
                    <ProductPriceContainer className="discounted">
                    <TotalPrice>(
                        <CurrencyFormat
                          value={product.promoAmount}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Rp '}
                        />)
                      </TotalPrice>
                    </ProductPriceContainer>
                  </ProductPriceRow> : null 
                }
                </div>
              ))
            }
            {
              (this.props.shoppingCart.filter((p) => p.type === 'wifi').length === 0) ? null :
              <div className="item-group">
                  <OngkirHead>
                    {this.props.data.settings.data.deposit>0?'Deposit Jaminan Peminjaman Device':''}
                  </OngkirHead>
                  <ProductPriceContainer>
                    <TotalPrice>
                      {this.props.data.settings.data.deposit > 0?
                      <CurrencyFormat
                        value={this.props.data.settings.data.deposit * this.props.numberOfDevices}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Rp '}
                      />:null}
                    </TotalPrice>
                    <div
                      style={{
                        marginLeft: '10px',
                        flexGrow: 1,
                      }}
                    >
                      Jumlah Device: {this.props.numberOfDevices}
                    </div>
                  </ProductPriceContainer>
                </div>
            }
            {
              this.state.selectedCourier ?
              <div className="item-group">
                  <OngkirHead>
                    Biaya Pengiriman
                  </OngkirHead>
                  <ProductPriceContainer>
                    <TotalPrice>
                      <CurrencyFormat
                        value={this.state.selectedCourier.total_price}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Rp '}
                      />
                    </TotalPrice>
                    <div
                      style={{
                        marginLeft: '10px',
                        flexGrow: 1,
                      }}
                    >
                      ({this.state.selectedCourier.label})
                    </div>
                  </ProductPriceContainer>
                </div>:null
            }
            
          <div
            style={{
              fontSize: '.75rem',
              opacity: 0.8
            }}
          >
            <div style={{ marginBottom: '-7px' }} >
              SubTotal: <CurrencyFormat
                value={this.getTotal()}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'Rp '}
              />
            </div>
            {
              this.state.idleDays ? (
                <div style={{marginBottom: '-7px'}} >
                  Idle Fee: <CurrencyFormat
                    value={this.state.idleDays*this.props.data.settings.data.idlefee}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'Rp '}
                  />
                </div>
              ) : null
            }
          </div>
          <div className="item-group">
            <Label>
              Total:
            </Label>
            <SubTotal>
              <CurrencyFormat
                value={this.getTotalAfterPromo()}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'Rp '}
              />
            </SubTotal>
          </div>
          <CheckoutButtonContainer>
            <CheckoutButton disabled={this.state.checkLoad} style={{opacity:this.state.checkLoad?0.5:1}}>
              {this.state.checkLoad ? 
              <svg width="27" height="28" viewBox="0 0 135 140" xmlns="http://www.w3.org/2000/svg" fill="#fff">
                <rect y="10" width="15" height="120" rx="6">
                    <animate attributeName="height"
                        begin="0.5s" dur="1s"
                        values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="y"
                        begin="0.5s" dur="1s"
                        values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                        repeatCount="indefinite" />
                </rect>
                <rect x="30" y="10" width="15" height="120" rx="6">
                    <animate attributeName="height"
                        begin="0.25s" dur="1s"
                        values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="y"
                        begin="0.25s" dur="1s"
                        values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                        repeatCount="indefinite" />
                </rect>
                <rect x="60" width="15" height="140" rx="6">
                    <animate attributeName="height"
                        begin="0s" dur="1s"
                        values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="y"
                        begin="0s" dur="1s"
                        values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                        repeatCount="indefinite" />
                </rect>
                <rect x="90" y="10" width="15" height="120" rx="6">
                    <animate attributeName="height"
                        begin="0.25s" dur="1s"
                        values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="y"
                        begin="0.25s" dur="1s"
                        values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                        repeatCount="indefinite" />
                </rect>
                <rect x="120" y="10" width="15" height="120" rx="6">
                    <animate attributeName="height"
                        begin="0.5s" dur="1s"
                        values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="y"
                        begin="0.5s" dur="1s"
                        values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                        repeatCount="indefinite" />
                </rect>
              </svg>
              :'Proses Order'}
            </CheckoutButton>
          </CheckoutButtonContainer>
        </ResponsiveCard>
      </Columns>
    </FlexBoxs>
  )};
  copyToClipboard = () => {
    var textField = document.createElement('textarea')
    textField.innerText = this.state.vaNumber
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    toast.success('Nomor Virtual Account berhasil disalin', {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true
    });
  }
  saveRefund = () => {
    if (this.state.bankAccountOwner && this.state.bankName && this.state.bankNumber){
      this.setState({
        refund:{
          bankName: this.state.bankName,
          bankAccount: this.state.bankNumber,
          bankAccountOwner: this.state.bankAccountOwner
        },
        modalRefund:false,
      });
    } else {
      toast.error('Silahkan Lengkapi Data Rekening Terlebih dahulu', {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        style: {zIndex:'1002'},
      });
    }
  }
  savePinpoint = () => {
    var centerMap = this.state.map.getCenter(), lat = centerMap.lat(), lng = centerMap.lng();
    axios.get(`https://maps.googleapis.com/maps/api/geocode/json`,{params:{key:process.env.GATSBY_GMAPS_API,language:'id',latlng:`${lat},${lng}`}})
    .then(({data}) => {
      let MapText = data.plus_code.compound_code;
      this.setState(prevState => ({
        pinpoint: {
          ...prevState.pinpoint,
          lat,lng,
          text: MapText.substring(MapText.indexOf(' ')+1),
        },
        modalMap: false,
      }),()=>console.log(this.state.pinpoint))
    })
    .catch((error) => {
      console.log({error})
    })
  }
  saveAddress = () =>{
    const {name,phoneNumber,email,address,selectedCity,selectedDistrict,selectedProvince,selectedRegion,postalCode,pinpoint,lat,lng} = this.state;
    const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let errName,errPhone,errEmail,errAdd,errSelAdd;
    if (!(name&&name.length)){errName= 'Nama harus diisi'}
    if (!(phoneNumber&&phoneNumber.length >= 8)){errPhone = 'Minimal 8 angka';}
    if (!(email&&reEmail.test(email.toLowerCase()))) {errEmail = 'Email harus diisi dengan format yang benar'}
    if (!(address&&address.length)){errAdd = 'Silahkan masukkan alamat anda'}
    if (!(selectedCity&&selectedDistrict&&selectedProvince&&selectedRegion)){
      errSelAdd = "Silahkan lengkapi detail alamat."
    }
    if (errName || errEmail || errPhone || errAdd || errSelAdd){
      this.setState(prevState=>({
        addressError:{
          ...prevState.addressError,
          name: errName,
          email: errEmail,
          phone: errPhone,
          address: errAdd,
          selectedAddress: errSelAdd
        }
      }));
    } else {
      this.fetchShipping();
    }
  }
  fetchShipping = () => {
      const {name,phoneNumber,address,selectedCity,selectedDistrict,selectedProvince,selectedRegion,postalCode,pinpoint,lat,lng} = this.state;
      this.setState({
        addressError:null,
        modalAddress:false,
        loadingShipInfo:true,
        destination: {
          name,
          phone:phoneNumber,
          "address 1": address,
          areaid: selectedDistrict.value.toString(),
          areaname: selectedDistrict.label,
          postcode: postalCode,
          suburbid: selectedRegion.value.toString(),
          suburbname: selectedRegion.label,
          cityid: selectedCity.value.toString(),
          cityname: selectedCity.label,
          countryid: '228',
          countryname: 'INDONESIA',
          provinceid: selectedProvince.value.toString(),
          provincename: selectedProvince.label,
          lat: pinpoint?pinpoint.lat.toString():null,
          lng: pinpoint?pinpoint.lng.toString():null,
        }
      },()=>{
        const params = {
          devicenum: this.props.numberOfDevices,
          destination: this.state.destination,
        }
        let diffDays = moment(this.state.departureDate).startOf('day').diff(moment().startOf('day'),'days');
        axios.post(`https://api.travelwifi.id/api/shipment/rates`,params)
        .then(({data}) => {
          let type={},shippingType=[],shippingOptions=[];
          console.log({data})
          data.data.pricings.forEach(elem=>{
            if (elem.max_day<=diffDays || elem.rate.type == 'Pick-Up'){
              type[elem.rate.type] = {
                'data':[],
                'min_day':10,
                'max_day':0,
                'min_price':10000000,
                'max_price':0
              }
            }
          })
          data.data.pricings.forEach(elem=>{
            let shp = elem.rate.type;
            if (elem.max_day<=diffDays || shp == 'Pick-Up'){
              type[shp].value = shp,
              type[shp].label = shp,
              type[shp].min_day = Math.min(type[elem.rate.type].min_day,elem.min_day),
              type[shp].max_day = Math.max(type[elem.rate.type].max_day,elem.max_day),
              type[shp].min_price = Math.min(type[elem.rate.type].min_price,elem.total_price),
              type[shp].max_price = Math.max(type[elem.rate.type].max_price,elem.total_price),
              type[shp].data.push({
                label: `${elem.logistic?elem.logistic.name:''} ${elem.rate.name}`,
                total_price: elem.total_price,
                value: (`${elem.logistic?elem.logistic.name:''}-${elem.rate.name}`).toLocaleLowerCase(),
                min_day: elem.min_day,
                max_day: elem.max_day,
                rate_id: elem.rate.id,
                rate_type: elem.rate.type,
                rate_name: elem.rate.name,
                logistic_name: elem.logistic?elem.logistic.name:'',
                logistic_code: elem.logistic?elem.logistic.code:'',
                logistic_logo_img: elem.logistic?elem.logistic.logo_url:'',
              });
            }
          })
          if ('Pick-Up' in type) {
            shippingType.push({
              value: 'Pick-Up',
              label : 'Pick up in Office',
              min_price : 0,
              max_price : 0,
              min_day: '-',
              max_day: '',
            })
          }
          if ('Instant' in type) {
            shippingType.push({
              value: 'Instant',
              label : 'Instant (3 jam)',
              min_price : type['Instant'].min_price,
              max_price : type['Instant'].max_price,
            })
          }
          if ('Same Day' in type) {
            shippingType.push({
              value: 'Same Day',
              label : 'Same Day (4-8 jam)',
              min_day : '4-',
              max_day : '8 jam',
              min_price : type['Same Day'].min_price,
              max_price : type['Same Day'].max_price,
            })
          }
          if ('Express' in type) {
            shippingType.push({
              value: 'Express',
              label : `${type['Express'].label} (${type['Express'].min_day}-${type['Express'].max_day} hari)`,
              min_price : type['Express'].min_price,
              max_price : type['Express'].max_price,
            })
          }
          if ('Regular' in type) {
            shippingType.push({
              value: 'Regular',
              label : `${type['Regular'].label} (${type['Regular'].min_day}-${type['Regular'].max_day} hari)`,
              min_price : type['Regular'].min_price,
              max_price : type['Regular'].max_price,
            })
          }
          if ('Trucking' in type) {
            shippingType.push({
              value: 'Trucking',
              label : `${type['Trucking'].label} (${type['Trucking'].min_day}-${type['Trucking'].max_day} hari)`,
              min_price : type['Trucking'].min_price,
              max_price : type['Trucking'].max_price,
            })
          }
          this.setState({
            shippingInfo:type,
            shippingType,
            loadingShipInfo:false,
            selectedShipping:shippingType[0],
            selectedCourier:type[shippingType[0].value].data[0]})
        })
        .catch((error) => {
          console.log({error});
          this.setState({loadingShipInfo:false,shippingInfo:null,shippingType:[]});
          toast.error(<FetchShipMsg />, {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true
          });
        })
      });
  }
  loadMap = (map,maps) =>{
    if (map && maps) {
      this.setState({map,maps,mapReady:true});
    }
  }
  handleSearchMap = (place) =>{
    let loct = place[0].geometry.location;
    this.state.map.setCenter(loct,10)
    console.log('change place')
  }
  render() {
    const { selectedProvince,selectedCity,selectedRegion,selectedDistrict,pinpoint,lat,lng,addressError,map,maps,mapReady } = this.state;
    return (
      <Layout>
        <Helmet>
          <title>Checkout | Wifi Republic</title>
          <script src="/jquery.min.js" />
          <script src="/theme.js" />
          <link rel="stylesheet" href="/theme2.css" />
          <link rel="stylesheet" href="/gilroy.css" />
        </Helmet>
        <Container style={{marginTop:'2rem'}}>
          <form onSubmit={this.checkoutHandler}>
            <this.FirstFormPanel />
            {/* <SwipeableViews index={this.state.index} onChangeIndex={this.handleChangeIndex}>
              {this.FirstFormPanel()}
              {this.SecondFormPanel()}
            </SwipeableViews> */}
          </form>
          <Modal 
              visible={this.state.showModal}
              width="500"
              effect="fadeInUp"
          >
              <ModalBox style={{backgroundColor:"#e9e9e9"}}>
                  <ModalHeader>PEMBAYARAN</ModalHeader>
                { this.state.vaLoaded==0 ? 
                  <ModalContent>
                    <svg id="wrap" width="300" height="300">
                      <svg>
                        <circle cx="150" cy="150" r="130" style={{stroke: "#e4de88", strokeWidth:18, fill:"transparent"}}/>
                        <circle cx="150" cy="150" r="115" fill="#b76568"/>
                        <path style={{stroke: "#b76568", strokeDasharray:820, strokeDashoffset:820, strokeWidth:18, fill:"transparent"}} d="M150,150 m0,-130 a 130,130 0 0,1 0,260 a 130,130 0 0,1 0,-260">
                          <animate attributeName="stroke-dashoffset" dur="6s" to="-820" repeatCount="indefinite"/>
                        </path>
                      </svg>
                      <svg>
                        <path id="hourglass" d="M150,150 C60,85 240,85 150,150 C60,215 240,215 150,150 Z" style={{stroke: "#fff", strokeWidth :5, fill:"#fff"}} />
                        <path id="frame" d="M100,97 L200, 97 M100,203 L200,203 M110,97 L110,142 M110,158 L110,200 M190,97 L190,142 M190,158 L190,200 M110,150 L110,150 M190,150 L190,150" style={{stroke:"#e4de88", strokeWidth:6, strokeLinecap:"round"}} />
                        <animateTransform xlinkHref="#frame" attributeName="transform" type="rotate" begin="0s" dur="3s" values="0 150 150; 0 150 150; 180 150 150" keyTimes="0; 0.8; 1" repeatCount="indefinite" />
                        <animateTransform xlinkHref="#hourglass" attributeName="transform" type="rotate" begin="0s" dur="3s" values="0 150 150; 0 150 150; 180 150 150" keyTimes="0; 0.8; 1" repeatCount="indefinite" />
                      </svg>
                      <svg>
                        <polygon id="upper" points="120,125 180,125 150,147" style={{fill:"#b76568"}}>
                          <animate attributeName="points" dur="3s" keyTimes="0; 0.8; 1" values="120,125 180,125 150,147; 150,150 150,150 150,150; 150,150 150,150 150,150" repeatCount="indefinite"/>
                        </polygon>
                        
                        <path id="line" strokeLinecap="round" strokeDasharray="1,4" strokeDashoffset="200.00" stroke="#b76568" strokeWidth="2" d="M150,150 L150,198">
                          <animate attributeName="stroke-dashoffset" dur="3s" to="1.00" repeatCount="indefinite"/>
                          <animate attributeName="d" dur="3s" to="M150,195 L150,195" values="M150,150 L150,198; M150,150 L150,198; M150,198 L150,198; M150,195 L150,195" keyTimes="0; 0.65; 0.9; 1" repeatCount="indefinite"/>
                          <animate attributeName="stroke" dur="3s" keyTimes="0; 0.65; 0.8; 1" values="#b76568;#b76568;transparent;transparent" to="transparent" repeatCount="indefinite"/>
                        </path>
                        <g id="lower">
                          <path d="M150,180 L180,190 A28,10 0 1,1 120,190 L150,180 Z" style={{stroke: "transparent", strokeWidth:5, fill:"#b76568"}}>
                            <animateTransform attributeName="transform" type="translate" keyTimes="0; 0.65; 1" values="0 15; 0 0; 0 0" dur="3s" repeatCount="indefinite" />
                          </path>
                          <animateTransform xlinkHref="#lower" attributeName="transform"
                                        type="rotate"
                                        begin="0s" dur="3s"
                                        values="0 150 150; 0 150 150; 180 150 150"
                                        keyTimes="0; 0.8; 1"
                                        repeatCount="indefinite"/>
                        </g>
                        <path d="M150,150 C60,85 240,85 150,150 C60,215 240,215 150,150 Z" style={{stroke: "#fff", strokeWidth:5, fill:"transparent"}}>
                          <animateTransform attributeName="transform"
                                        type="rotate"
                                        begin="0s" dur="3s"
                                        values="0 150 150; 0 150 150; 180 150 150"
                                        keyTimes="0; 0.8; 1"
                                        repeatCount="indefinite"/>
                        </path>
                        <path id="frame" d="M100,97 L200, 97 M100,203 L200,203" style={{stroke:"#e4de88", strokeWidth:6, strokeLinecap:"round"}}>
                          <animateTransform attributeName="transform"
                                        type="rotate"
                                        begin="0s" dur="3s"
                                        values="0 150 150; 0 150 150; 180 150 150"
                                        keyTimes="0; 0.8; 1"
                                        repeatCount="indefinite"/>
                        </path>
                      </svg>
                    </svg>
                  </ModalContent>
                  : this.state.vaLoaded==1 ?
                    <ModalContent>
                      <FrameBox>
                        <FrameRow>
                          <FrameCol>Total Pembayaran</FrameCol>
                          <FrameCol style={{textAlign:'right',color:'#c13517'}}>
                            <CurrencyFormat
                              value={this.getTotalAfterPromo()}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'Rp. '}
                            />
                          </FrameCol>
                        </FrameRow>
                      </FrameBox>
                      <FrameBox >
                        <FrameRow>
                          <FrameColLeft>
                          <svg xmlns="http://www.w3.org/2000/svg"  version="1.0" viewBox="0 0 155 155" style={{display: 'inline',width:'30px',height:'30px',marginTop:'.2rem'}}>
                            <g id="layer2" style={{display: 'inline'}}>
                              <path d="M 133.55007,19.777503 C 152.33479,60.698263 149.70987,99.937759 133.81541,132.54868 C 99.995415,149.53439 58.393919,150.78761 20.778894,131.75265 C 2.6125726,93.342911 3.1729365,49.976576 20.778894,18.981471 C 54.920887,2.0292511 97.716017,1.5217338 133.55007,19.777503 z M 135.67755,17.136735 C 155.21347,59.692249 152.48359,100.49933 135.9535,134.41304 C 100.78102,152.07732 57.515869,153.3806 18.3966,133.5852 C -0.49620078,93.641031 0.086572285,48.542239 18.3966,16.308902 C 53.903945,-1.3205463 98.410472,-1.8483386 135.67755,17.136735 z" id="path3324" 
                                style={{fill: 'rgb(0, 95, 175)', fillOpacity: 1, fillRule: 'evenodd', stroke: 'rgb(0, 95, 175)', strokeWidth: '1px', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeOpacity: 1}}/>
                              <path d="M 65.586233,113.41171 L 68.136676,113.41171 C 68.136676,113.41171 69.688285,103.22396 58.877026,95.479339 C 52.0608,90.59655 32.425106,89.551831 29.291488,83.172615 C 22.96449,92.62189 28.107848,103.54542 35.192246,106.06443 C 46.477335,110.20783 60.567077,98.025438 65.586233,113.41171 z" id="path3357" 
                                style={{fill: 'rgb(0, 95, 175)', fillOpacity: 1, fillRule: 'evenodd', stroke: 'rgb(0, 95, 175)', strokeWidth: '1px', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeOpacity: 1}}/>
                              <path d="M 84.224072,113.41171 L 86.578326,113.41171 C 93.705524,96.803152 106.93259,114.01834 120.72779,104.1246 C 126.27812,100.14398 128.18927,92.380544 122.87307,83.172615 C 119.79881,92.460951 86.164327,86.647089 84.224072,113.41171 z" id="path3359" 
                                style={{fill: 'rgb(0, 95, 175)', fillOpacity: 1, fillRule: 'evenodd', stroke: 'rgb(0, 95, 175)', strokeWidth: '1px', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeOpacity: 1}}/>
                              <path d="M 74.807059,113.41171 L 77.749877,113.41171 C 77.753657,102.23396 75.679843,83.873094 81.127147,69.857767 C 87.795685,56.783762 93.152638,58.669861 98.509481,45.545208 C 101.62372,32.418254 96.01273,18.319424 77.749877,16.444792 C 56.684378,17.577704 50.414545,32.291138 54.097934,45.888478 C 56.425108,54.479293 66.925018,59.263387 71.074028,69.898666 C 76.550502,83.92699 74.546053,102.98965 74.807059,113.41171 z" id="path3365" 
                                style={{fill: 'rgb(0, 95, 175)', fillOpacity: 1, fillRule: 'evenodd', stroke: 'rgb(0, 95, 175)', strokeWidth: '1px', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeOpacity: 1}}/>
                              <path d="M 70.528274,113.40992 C 69.981567,107.30577 68.521995,102.1761 65.392075,98.250601 C 51.040292,80.250808 21.699695,93.801665 29.239191,63.213018 C 33.159259,47.574656 77.871697,38.351885 72.737797,113.38485 L 70.528274,113.40992 z" id="path3371" 
                                style={{fill: 'rgb(0, 95, 175)', fillOpacity: 1, fillRule: 'evenodd', stroke: 'rgb(0, 95, 175)', strokeWidth: '1px', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeOpacity: 1}}/>
                              <path d="M 79.71382,113.52065 L 82.333943,113.52065 C 82.37925,104.77939 85.419796,99.013838 91.199312,93.987211 C 106.4892,82.876855 130.35868,90.307617 122.94585,63.21429 C 117.53528,49.712004 93.137796,44.407114 82.476075,74.427256 C 79.272581,83.447315 79.556612,95.914594 79.71382,113.52065 z" id="path3231" 
                                style={{fill: 'rgb(0, 95, 175)', fillOpacity: 1, fillRule: 'evenodd', stroke: 'rgb(0, 95, 175)', strokeWidth: '1px', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeOpacity: 1}}/>
                              <path d="M 41.591028,125.96624 L 45.128193,127.1453 L 43.425114,133.04057 L 41.984046,132.64755 L 42.115052,131.73051 C 40.351568,132.53407 37.122451,131.93192 36.7438,129.89643 C 36.364358,128.26147 37.512584,123.30452 39.363923,120.98801 C 41.163098,118.73677 45.54525,120.93783 45.652218,122.29807 C 45.758874,123.65433 45.223612,125.1802 45.223612,125.1802 L 43.573914,124.86859 C 43.573914,124.86859 43.674144,124.3783 43.818132,123.60813 C 43.97107,122.79008 41.838594,121.97116 41.198009,122.82209 C 40.601481,123.6145 39.406151,126.38025 39.232917,128.58636 C 39.049461,130.92265 41.293436,130.43051 41.85304,130.15844 C 42.304308,129.93904 42.639076,128.06234 42.639076,128.06234 L 41.067003,127.53831 L 41.591028,125.96624 z" id="path3233" 
                                style={{fill: 'rgb(0, 95, 175)', fillOpacity: 1, fillRule: 'evenodd', stroke: 'rgb(0, 95, 175)', strokeWidth: '1px', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeOpacity: 1}}/>
                              <path d="M 47.41143,133.97765 L 49.950863,122.5502 C 56.156119,123.22605 56.699579,124.95436 56.822272,126.80748 C 56.577679,128.24677 56.193806,129.25003 54.955038,129.4963 C 56.405873,130.81239 54.972972,133.57034 54.955038,135.62081 L 52.789052,135.09799 C 53.04315,133.30756 55.166118,129.6665 50.969575,129.6665 L 49.801485,134.42578 L 47.41143,133.97765 z M 51.444647,127.70376 L 52.11685,125.01495 C 54.596375,125.0399 54.555307,126.03432 54.506902,127.03156 C 53.790269,128.62415 52.524995,127.74787 51.444647,127.70376 z" id="path3234" 
                                style={{fill: 'rgb(0, 95, 175)', fillOpacity: 1, fillRule: 'evenodd', stroke: 'rgb(0, 95, 175)', strokeWidth: '1px', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeOpacity: 1}}/>
                              <path d="M 60.492096,124.39567 L 62.74416,124.74756 L 61.758881,132.77053 C 61.882971,135.4256 64.546127,135.26665 65.066601,133.33355 L 66.122253,124.95869 L 68.022431,125.09944 L 67.177909,134.31882 C 66.325492,138.37843 60.270529,137.83535 59.506818,133.96694 L 60.492096,124.39567 z" id="path3243" 
                                style={{fill: 'rgb(0, 95, 175)', fillOpacity: 1, fillRule: 'evenodd', stroke: 'rgb(0, 95, 175)', strokeWidth: '1px', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeOpacity: 1}}/>
                              <path d="M 71.189395,137.41541 L 71.822788,125.38095 C 76.215129,125.47173 78.733569,126.07964 78.649352,129.18131 C 78.388386,133.42056 74.956278,132.46744 73.37108,132.41865 L 72.948817,137.48579 L 71.189395,137.41541 z M 74.004473,127.56264 L 73.58221,130.51847 C 74.668113,130.41167 76.005007,131.34869 76.538039,129.25168 C 76.497544,127.61677 75.254427,127.52725 74.004473,127.56264 z" id="path3235" 
                                style={{fill: 'rgb(0, 95, 175)', fillOpacity: 1, fillRule: 'evenodd', stroke: 'rgb(0, 95, 175)', strokeWidth: '1px', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeOpacity: 1}}/>
                              <path d="M 88.361373,136.99315 L 86.461194,125.38095 C 87.44371,125.22027 92.25828,123.45649 93.42851,126.64774 C 94.005957,128.59715 93.407389,129.29725 92.724744,130.16659 C 94.018405,130.58306 94.456004,131.59089 94.765673,132.70015 C 94.86363,134.45262 94.269217,135.61725 93.147002,136.00787 L 88.361373,136.99315 z M 88.854009,126.64774 L 89.417024,129.67395 C 90.79776,129.36731 91.508213,128.54959 91.38758,127.49226 C 91.206922,126.68764 90.53445,126.09333 88.854009,126.64774 z M 89.90966,131.92601 L 90.402302,135.02259 C 91.555207,135.05152 92.607128,134.35858 92.30248,132.91129 C 92.009488,131.51938 90.839736,131.73973 89.90966,131.92601 z" id="path3240" 
                                style={{fill: 'rgb(0, 95, 175)', fillOpacity: 1, fillRule: 'evenodd', stroke: 'rgb(0, 95, 175)', strokeWidth: '1px', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeOpacity: 1}}/>
                              <path d="M 102.0145,126.29585 L 104.05544,125.94397 C 103.62874,124.45914 103.90692,122.34051 99.199423,123.05852 C 96.331306,123.71723 97.167021,127.31851 97.65113,130.7296 C 98.482411,134.71452 99.084016,135.31314 102.15525,135.09297 C 103.58695,134.81734 105.50177,134.4059 104.97033,130.37772 L 102.9294,130.65922 C 103.15094,131.73399 103.39495,132.55819 102.22563,133.40392 C 101.2169,133.5339 100.77117,133.77076 100.11432,132.48902 L 99.058671,126.92925 C 98.855367,125.74378 99.242292,125.04233 100.1847,124.6068 C 101.21927,124.51316 101.6891,125.0199 102.0145,126.29585 z" id="path3253" 
                                style={{fill: 'rgb(0, 95, 175)', fillOpacity: 1, fillRule: 'evenodd', stroke: 'rgb(0, 95, 175)', strokeWidth: '1px', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeOpacity: 1}}/>
                              <path d="M 108.34842,133.40392 L 107.71503,121.29909 L 110.10785,120.6657 L 116.01951,130.65922 L 114.40084,131.71488 L 112.43029,128.8998 L 110.17822,129.74432 L 110.38936,132.70015 L 108.34842,133.40392 z M 109.61521,124.18454 L 109.82634,128.12565 L 112.00802,127.49226 L 109.61521,124.18454 z" id="path3255" 
                                style={{fill: 'rgb(0, 95, 175)', fillOpacity: 1, fillRule: 'evenodd', stroke: 'rgb(0, 95, 175)', strokeWidth: '1px', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeOpacity: 1}}/>
                            </g>
                          </svg>
                          </FrameColLeft>
                          <FrameCol>
                            <span style={{borderBottom:'solid 1px #eee',paddingTop:'.125rem', paddingBottom:'.4rem',display:'block'}}>Bank BCA (Dicek Otomatis)</span>
                            <div style={{display:'flex',flexDirection:'column',borderBottom:'solid 1px #eee',paddingBottom:'.25rem',marginBottom:'.25rem'}}>
                              <span style={{display:'flex',color:'#bbb',fontSize:'.7rem',fontWeight:'normal'}}>No Virtual Account</span>
                              <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingRight:'.5rem'}}>
                                <span style={{color:'#E92028',display:'flex',flexGrow:1,fontSize:'1.125rem',lineHeight:'100%'}}>{this.state.vaNumber}</span>
                                <button 
                                  onClick={()=>this.copyToClipboard()} 
                                  style={{color:'#90c0b4',display:'flex',flexGrow:0,fontSize:'.7rem',border:'none',paddingTop:0,paddingBottom:0,background:'transparent',cursor:'pointer'}}>
                                  SALIN
                                </button>
                              </div>
                            </div>
                            <span style={{color:'#90c0b4',fontSize:'.675rem',display:'flex'}}>Dicek dalam 10 menit setelah pembayaran berhasil</span>
                            <span style={{fontSize:'.75rem',display:'flex',fontWeight:'normal'}}>Selesaikan transaksi anda sebelum <br/> <strong>{this.state.vaExpiry}</strong></span>
                          </FrameCol>
                        </FrameRow>
                      </FrameBox>
                      <AccordionContainer>
                        <Accordion style={{borderBottom:'solid 2px #eee'}} onClick={()=>this.setState({accordOpen:this.state.accordOpen==1?0:1})}>
                          <AccordionHead>
                            <span style={{display:'flex',flexGrow:1}}>Petunjuk Transfer ATM</span>
                            <Expand active={this.state.accordOpen==1} style={{display:'flex',flexGrow:0}} />
                          </AccordionHead>
                          <AccordionBody active={this.state.accordOpen==1}>
                            <AccordionList>
                              <li>Pilih <strong>Transaksi Lainnya &gt; Transfer &gt; Ke Rek BCA Virtual Account</strong></li>
                              <li>Masukkan nomor virtual account <VANum>{this.state.vaNumber}</VANum> dan pilih <strong>Benar</strong></li>
                              <li>Periksa informasi yang tertera di layar. Pastikan <strong>Merchant</strong> adalah <strong>WifiRepublic</strong> dan <strong>Total Tagihan</strong> sudah benar. Jika benar pilih <strong>Ya</strong></li>
                            </AccordionList>
                          </AccordionBody>
                        </Accordion>
                        <Accordion style={{borderBottom:'solid 2px #eee'}} onClick={()=>this.setState({accordOpen:this.state.accordOpen==2?0:2})}>
                          <AccordionHead>
                            <span style={{display:'flex',flexGrow:1}}>Petunjuk Transfer iBanking / KlikBCA</span>
                            <Expand active={this.state.accordOpen==2} style={{display:'flex',flexGrow:0}} />
                          </AccordionHead>
                          <AccordionBody active={this.state.accordOpen==2}>
                            <AccordionList>
                              <li>Pilih <strong>Transfer Dana &gt; Transfer ke Rek BCA Virtual Account</strong></li>
                              <li>Centang <strong>nomor virtual account</strong> dan masukkan <VANum>{this.state.vaNumber}</VANum>. Klik <strong>Lanjutkan</strong></li>
                              <li>Periksa informasi yang tertera di layar. Pastikan <strong>Merchant</strong> adalah <strong>WifiRepublic</strong> dan <strong>Total Tagihan</strong> sudah benar. Jika benar, klik <strong>Lanjutkan</strong></li>
                              <li>Masukkan respon keyBCA Anda dan klik<strong>Kirim</strong></li>
                            </AccordionList>
                          </AccordionBody>
                        </Accordion>
                        <Accordion onClick={()=>this.setState({accordOpen:this.state.accordOpen==3?0:3})}>
                          <AccordionHead>
                            <span style={{display:'flex',flexGrow:1}}>Petunjuk Transfer mBanking / mBCA</span>
                            <Expand active={this.state.accordOpen==3} style={{display:'flex',flexGrow:0}} />
                          </AccordionHead>
                          <AccordionBody active={this.state.accordOpen==3}>
                            <AccordionList>
                              <li>Pilih <strong>m-Transfer - BCA Virtual Account</strong></li>
                              <li>Masukkan nomor virtual account <VANum>{this.state.vaNumber}</VANum> dan pilih <strong>Send</strong></li>
                              <li>Periksa informasi yang tertera di layar. Pastikan <strong>Merchant</strong> adalah <strong>WifiRepublic</strong> dan <strong>Total Tagihan</strong> sudah benar. Jika benar pilih <strong>OK</strong></li>
                              <li>Masukkan PIN m-BCA Anda dan pilih <strong>OK</strong></li>
                            </AccordionList>
                          </AccordionBody>
                        </Accordion>
                      </AccordionContainer>
                      <ButtonGroup>
                        <ButtonDirectPay onClick={() => this.directProcess()}>OK</ButtonDirectPay>
                      </ButtonGroup>
                    </ModalContent>
                    : <ModalContent>
                        <h3>Terjadi kesalahan saat meminta status VirtualAccount</h3>
                        <ButtonGroup>
                          <ButtonDirectPay onClick={() => this.closeModal()}>OK</ButtonDirectPay>
                        </ButtonGroup>
                      </ModalContent>
                    }
              </ModalBox>
          </Modal>
          <Modal 
              visible={this.state.modalRefund}
              width="500"
              effect="fadeInUp"
          >
              <ModalBox>
                  <ModalHeader>
                    Rekening Refund
                    <CloseModal onClick={()=> this.setState({modalRefund:false})}>&times;</CloseModal>
                  </ModalHeader>
                  <ModalContent>
                  <div className="item-group">
                    <Label htmlFor="bank-number">Nomor Rekening Bank *</Label>
                    <TextInput id="bank-number" type="tel" value={this.state.bankNumber} disabled={this.state.checkLoad} onChange={this.changeBankNumberHandler} required />
                    <Label htmlFor="bank">Nama Bank *</Label>
                    <TextInput id="bank" type="text" value={this.state.bankName} disabled={this.state.checkLoad} onChange={this.changeBankNameHandler} required />
                    <Label htmlFor="bank-account-owner">Nama Pemilik Rekening *</Label>
                    <TextInput id="bank-account-owner" type="text" value={this.state.bankAccountOwner} disabled={this.state.checkLoad} onChange={this.changeBankAccountOwnerHandler} required />
                    <PromoSubtitle>
                      <small>
                      Informasi bank dibutuhkan untuk proses refund terhadap dana deposit
                      </small>
                    </PromoSubtitle>
                    <SaveButtonContainer>
                      <BackButton type="button" onClick={this.saveRefund}>Simpan Rekening</BackButton>
                    </SaveButtonContainer>
                  </div>
                  </ModalContent>
              </ModalBox>
          </Modal>
          <Modal 
              visible={this.state.modalAddress}
              width="420"
              effect="fadeInUp"
          >
              <ModalBox>
                <ModalHeader>
                  Alamat Pengiriman
                  <CloseModal onClick={()=> this.setState({modalAddress:false})}>&times;</CloseModal>
                </ModalHeader>
                <ModalContent>
                  <div className={this.props.shoppingCart.filter((p) => p.type === 'wifi' || p.type==='simcard').length === 0?"item-group":"d-none"}>
                    <Label >Tanggal berapa anda ingin menerima modemi?</Label>
                    <DatePicker
                      locale={isSSR ? 'en' : "id"}
                      selected={this.state.departureDate}
                      customInput={<ExampleCustomInput />}
                      minDate={new Date(moment().format('YYYY-MM-DD'))}
                      maxDate={new Date(this.getEarliestStartDate())}
                      onChange={this.changeDepartureDateHandler}
                      highlightDates={this.state.departureDate?[]:[new Date(this.getEarliestStartDate())]}
                      dateFormat="dd MMMM yyyy"
                      // ref={this.departureRef}
                      disabledKeyboardNavigation
                      required/>
                    {
                      this.state.idleDays ?
                        <PromoSubtitle style={{color: 'red'}} >
                            Idle Fee sebesar Rp. {this.state.idleDays*this.props.data.settings.data.idlefee}
                        </PromoSubtitle>
                        : null
                    }
                  </div>
                
                  <FlexBoxs2>
                    <ColumnsLeft>
                      <LabelForm>
                        Nama (sesuai KTP)
                        {addressError&&addressError.name?<ErrorText>{addressError.name}</ErrorText>:null}
                      </LabelForm>
                      <TextInputAdd type='text' value={this.state.name || ''} onChange={this.changeNameHandler} />
                    </ColumnsLeft>
                    <ColumnsRight>
                      <LabelForm>
                        Nomor Telepon
                        {addressError&&addressError.phone?<ErrorText>{addressError.phone}</ErrorText>:null}
                      </LabelForm>
                      <TextInputAdd type='text' value={this.state.phoneNumber || ''} onChange={this.changePhoneNumberHandler}></TextInputAdd>
                    </ColumnsRight>
                  </FlexBoxs2>
                  <DivLeft>
                    <LabelForm>
                      Email aktif (untuk pengiriman invoice)
                      {addressError&&addressError.email?<ErrorText>{addressError.email}</ErrorText>:null}
                      </LabelForm>
                    <TextInputAdd type='text' value={this.state.email || ''} onChange={this.changeEmailHandler}></TextInputAdd>
                  </DivLeft>
                  <DivLeft>
                    <LabelForm>
                      Alamat pengiriman
                      {addressError&&addressError.address?<ErrorText>{addressError.address}</ErrorText>:null}
                    </LabelForm>
                    <TextInputAdd type='text' value={this.state.address || ''} onChange={this.changeAddressHandler}></TextInputAdd>
                  </DivLeft>
                  <FlexBoxs2>
                    <ColumnsLeft>
                      <LabelForm>Provinsi</LabelForm>
                      <AsyncSelect
                        value={selectedProvince}
                        className="selectar-container"
                        classNamePrefix="selectar"
                        cacheOptions defaultOptions
                        loadOptions = {this.loadProvinces}
                        menuPlacement="top"
                        onChange={this.selectProv}
                      />
                    </ColumnsLeft>
                    <ColumnsRight>
                      <LabelForm>Kabupaten/Kota</LabelForm>
                      <AsyncSelect
                        key={JSON.stringify(selectedProvince)}
                        value={selectedCity}
                        className="selectar-container"
                        classNamePrefix="selectar"
                        cacheOptions defaultOptions
                        isDisabled={this.state.disabledCity}
                        loadOptions = {this.loadCities}
                        menuPlacement="top"
                        onChange={this.selectCity}
                      />
                    </ColumnsRight>
                  </FlexBoxs2>
                  <FlexBoxs2>
                    <Columns30>
                      <ColumnsLeft>
                        <LabelForm>Kecamatan</LabelForm>
                        <AsyncSelect
                          key={JSON.stringify(selectedCity)}
                          value={selectedRegion}
                          className="selectar-container"
                          classNamePrefix="selectar"
                          cacheOptions defaultOptions
                          isDisabled={this.state.disabledRegion}
                          loadOptions = {this.loadRegion}
                          menuPlacement="top"
                          onChange={this.selectRegion}
                        />
                      </ColumnsLeft>
                      <ColumnsLeft>
                        <LabelForm>Kelurahan</LabelForm>
                        <AsyncSelect
                          key={JSON.stringify(selectedRegion)}
                          value={selectedDistrict}
                          className="selectar-container"
                          classNamePrefix="selectar"
                          cacheOptions defaultOptions
                          isDisabled={this.state.disabledDistrict}
                          loadOptions = {this.loadDistrict}
                          menuPlacement="top"
                          onChange={this.selectDistrict}
                        />
                      </ColumnsLeft>
                    </Columns30>
                    <Columns31>
                      <LabelForm>Kode Pos</LabelForm>
                      <TextInputAdd type='text' value={this.state.postalCode || ''} readOnly></TextInputAdd>
                    </Columns31>
                  </FlexBoxs2>
                  {addressError&&addressError.selectedAddress?<ErrorText><small>{addressError.selectedAddress}</small></ErrorText>:null}
                  <DivLeft>
                    <LabelForm>Tandai Lokasi (opsional)</LabelForm>
                    <PointDiv>
                      <PointText>
                        {pinpoint?<this.Markers />:<this.MarkersOff />}
                        <PointLocation>
                          {pinpoint?pinpoint.text:'Tandai pinpoint lokasi di peta'}
                        </PointLocation>
                      </PointText>
                      <PointButton onClick={()=>this.setState({modalMap:true})}>
                        {pinpoint?'Ubah':'Tandai'}
                      </PointButton>
                    </PointDiv>
                  </DivLeft>
                  <div className="item-group">
                    <SaveButtonContainer>
                      <BackButton type="button" onClick={this.saveAddress}>Simpan Alamat</BackButton>
                    </SaveButtonContainer>
                  </div>
                </ModalContent>
              </ModalBox>
          </Modal>
          <Modal 
              visible={this.state.modalMap}
              width="400"
              effect="fadeInUp"
          >
            <ModalHeader style={{justifyContent:'flex-end'}}>
              <CloseModal onClick={()=> this.setState({modalMap:false})}>&times;</CloseModal>
            </ModalHeader>
            <ModalContent>
                {this.state.modalMap?
                <MapContainer>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.GATSBY_GMAPS_API,language:'id', libraries: ['places'] }}
                    options={{fullscreenControl:false}}
                    defaultCenter={pinpoint?{lat:pinpoint.lat,lng:pinpoint.lng}:{lat,lng}}
                    defaultZoom={16}
                    onGoogleApiLoaded={({map, maps}) => this.loadMap(map,maps)}
                    yesIWantToUseGoogleMapApiInternals
                  >
                  </GoogleMapReact>
                  {mapReady && (<SearchBox
                    placeholder={"Cari lokasi..."}
                    onPlacesChanged={this.handleSearchMap} 
                    map={map}
                    mapsapi={maps} />)}
                  <MarkerSet>
                    <MapPoint onClick={this.savePinpoint}>Simpan Pinpoint</MapPoint>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="50" width="50">
                      <path d="M18,9c0,3.314-6,11-6,11S6,12.314,6,9s2.686-6,6-6S18,5.686,18,9z" opacity=".3" fill="#853BBB" />
                      <path d="M12,11.5c-1.381,0-2.5-1.119-2.5-2.5c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5C14.5,10.381,13.381,11.5,12,11.5z" fill="#853BBB" />
                      <path d="M12,2.01c-3.856,0-6.993,3.137-6.993,6.993c0,4.483,5.968,11.767,6.222,12.074L12,22.012l0.771-0.935c0.254-0.308,6.222-7.591,6.222-12.074C18.993,5.146,15.856,2.01,12,2.01z M12,18.827c-1.878-2.463-4.993-7.115-4.993-9.824C7.007,6.25,9.247,4.01,12,4.01s4.993,2.24,4.993,4.993C16.993,11.708,13.878,16.362,12,18.827z" fill="#853BBB" />
                    </svg>
                  </MarkerSet>
                </MapContainer>
                :null}
                
            </ModalContent>
          </Modal>
        </Container>
      </Layout>
    )
  }
}
const mapStateToProps = state => ({
  shoppingCart: state.shoppingCart,
  userInfo: state.userInfo,
  referralCode: state.referralCode,
  promoCode: state.promoCode,
  numberOfDevices: state.numberOfDevices
});
const mapDispatchToProps = dispatch => ({
  updateUserInfo: (userInfo) => dispatch({ type: 'UPDATE_USER_INFO', userInfo }),
  changeReferral: (code) => dispatch({type: 'CHANGE_REFERRAL_CODE', code}),
  changePromo: (code) => dispatch({type: 'CHANGE_PROMO_CODE', code}),
  clearCart: () => dispatch({type: 'CLEAR_CART'}),
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);